.rpb-hero {
  z-index: 0;
  position: relative;

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  // Background Gallery
  .rpb-hero-gallery {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    .rpb-gallery-single {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
      z-index: 0;
      opacity: 0;
      animation: bg-fade-out 9s infinite both;
      &:nth-child(2) {
        animation-delay:3s;
      }
      &:nth-child(3) {
        animation-delay:6s;
      }
    }
  }
  // Video Background
  .absolute-slider,
  .hero-bkg-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #101010;

    .slick-list,
    .slick-slide,
    .slick-track {
      width: 100%;
      height: 100%;

      * {
        height: 100%;
      }
    }
  }
  /** embed video styles**/
  .hero-video {
    overflow: hidden !important;

    .autoplay-video {
      position: absolute;
      left: 50%;
      bottom: 0;
      -webkit-transform: translate(-50%, 0%) !important;
      -o-transform: translate(-50%, 0%) !important;
      transform: translate(-50%, 0%) !important;
      width: auto;
      min-width: 100%;
      min-height: 100%;
    }
  }
  /** youtube video styles **/
  .inline-YTPlayer {
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  .hero-bkg-youtube-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// Slider Styles
.rpb-hero-slider {
  .splide__slider {
    .splide__track {
      .splide__list {
        .splide__slide {
          padding-right: 4rem;
          padding-left: 4rem;
        }
      }
    }
  }
}

.home {
  .hero-col {
    background-color: $brand-primary;
    position: relative;
    &:nth-of-type(1) {
      background-image: url('../../images/AdobeStock_182031788.jpg');
    }
    &:nth-of-type(2) {
      background-image: url('../../images/AdobeStock_346885013.jpg');
    }
    .hero-content {
      z-index: 2;
    }
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba($dark,.2);
    }
  }
}
