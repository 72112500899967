//default banner
.page-header.default-banner {
  overflow: hidden;
  position: relative;
  h1, .h1, h2, .h2 {
    position: relative;
    z-index: 2;
    font-family: $heading-font;
    padding-top:25vw;
    padding-bottom:15px;
    &:before {
      display: none;
    }
    @include media-breakpoint-up(lg) {
      padding-top:6vw;
      margin-bottom:15px;
    }
  }
}
//center-banner
.negative-widget {
  @include media-breakpoint-up(lg) {
    margin-top: -108px;
  }
}
.flex-nav {
  @include media-breakpoint-up(xl) {
    display: flex;
    justify-content: flex-end;
  }
}
.content-page-title {
  @include media-breakpoint-down(sm) {
    font-size: 18px!important;
  }
}

.ecommerce-header {
  padding-top:6vw;
  padding-bottom:15px;
}
