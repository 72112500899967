.rpb-cards {
  .card {
    border:0;
    p {
      &:last-of-type {
        margin-bottom:0;
      }
    }
    .card-header {
      background:unset;
      border-bottom:unset;
    }
  }
  // Vertical Align Class
  .vertically-aligned-card {
    display:flex;
    flex-direction:column;
    justify-content:center;
  }
  // Image Classes
  .bg-img-card {
    display:none;
  }
  // Default Slider Styles
  &.bootstrap-cards-slider {
    .splide__slider {
      .rpb-slider-arrows {
        .splide__arrow--prev {
          left:0;
        }
        .splide__arrow--next {
          right:0;
        }
      }
      .splide__track {
        margin-left:3rem;
        margin-right:3rem;
      }
      .splide__pagination {
        bottom:-2.5rem;
      }
    }
  }
}

.rpb-cards .card-col .card, .hero-col {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($brand-secondary,.8);
    mix-blend-mode: color;
    transform-origin: bottom center;
    transform: scaleY(0);
    transition: $transition-med;
  }

  &:hover::before {
    transform: scaleY(1);
  }
}

.rpb-cards .card-col:nth-of-type(even) .card::before {
  transform-origin: top center;
}
