// Theme Colors/Branding
$brand-primary: #383D3B;
$brand-primary-rgb: rgba($brand-primary,1);
$brand-primary-offset: #383D3B;
$brand-secondary: #2F99CC;
$dark: #000000;
// $light: #EEE5E9;
$light: #eaeaea;
$medium: #dee2e6;
$inactive: #8c8c8c;
$gray: #888;
$white: #fff;
$accent: #2F99CC;
$accent-2: #2F99CC;
$body-font: #222;
$error: #d81e00;
